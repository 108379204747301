<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ loading ? "Student" : studentName }}'s Result</h1>
      </v-col>
    </v-row>

    <highcharts :options="chartOptions" class="mb-4"></highcharts>

    <v-data-iterator :items="results" hide-default-footer>
      <template v-slot:default="props">
        <v-row v-masonry>
          <v-col
            v-for="result in props.items"
            :key="result.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                <p>
                  <router-link
                    :to="{
                      name: 'Result.Test.GroupList',
                      params: { test_id: result.test_id }
                    }"
                    class="link"
                    >{{ result.test_name }}</router-link
                  >
                  •
                  <span
                    :class="
                      `${
                        parseScoreDisplay(
                          {
                            id: result.id,
                            score: result.test_score,
                            test_end: result.test_end
                          },
                          true
                        ).color
                      }--text`
                    "
                    >{{
                      parseScoreDisplay(
                        {
                          id: result.id,
                          score: result.test_score,
                          test_end: result.test_end
                        },
                        true
                      ).score
                    }}</span
                  >
                </p>
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item
                  v-for="subtest in result.subtests"
                  :key="subtest.name"
                >
                  <v-list-item-content>{{ subtest.name }}</v-list-item-content>
                  <v-menu :key="`${subtest.name}`" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-content
                        v-bind="attrs"
                        v-on="on"
                        :class="`${parseScoreDisplay(subtest).color}--text`"
                      >
                        <v-row align="center" justify="center">{{
                          parseScoreDisplay(subtest).score
                        }}</v-row>
                      </v-list-item-content>
                    </template>
                    <v-list
                      ><v-list-item
                        :disabled="subtest.id === null"
                        :inactive="subtest.id === null"
                        :to="{
                          name: 'Subtest.Review',
                          params: { id: subtest.id }
                        }"
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.reviewSubtest")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="startSubtest(result.id, subtest.subtest_id)"
                        :disabled="subtest.test_end !== null"
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.startSubtest")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="showForceEndSubtestPrompt(subtest.id)"
                        :disabled="
                          (subtest.test_end === null &&
                            subtest.score === '-') ||
                            (subtest.test_end !== null && subtest.score !== '-')
                        "
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.forceEndSubtest")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="showResetSubtestPrompt(subtest.id)"
                        :disabled="subtest.test_end === null"
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.resetSubtest")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="recalculateSubtestResult(subtest.id)"
                        :disabled="subtest.id === null"
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.recalculateScore")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="addSubtestDuration(subtest.id, 15)"
                        :disabled="subtest.id === null"
                        link
                      >
                        <v-list-item-title
                          >{{ $vuetify.lang.t("$vuetify.addDuration", "15") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="addSubtestDuration(subtest.id, 30)"
                        :disabled="subtest.id === null"
                        link
                      >
                        <v-list-item-title
                          >{{ $vuetify.lang.t("$vuetify.addDuration", "30") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item>
              </v-list>
              <div class="d-flex justify-end">
                <v-card-actions>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        {{ $vuetify.lang.t("$vuetify.option") }}
                        <v-icon right dark> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="
                          result.file !== null && downloadFile(result.file)
                        "
                        :disabled="result.file === null"
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.downloadResult")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="showResetPrompt(result.id)" link>
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.resetTest")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="result.test_score !== '-'"
                        @click="showForceEndTestPrompt(result.id)"
                        link
                      >
                        <v-list-item-title>{{
                          $vuetify.lang.t("$vuetify.forceEndTest")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="result.file === null"
                        @click="renewCertificate(result.id)"
                        link
                      >
                        <v-list-item-title
                          >{{ $vuetify.lang.t("$vuetify.renewCertificate") }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="grey lighten-1"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information
                              </v-icon>
                            </template>
                            <p class="text-center mb-0">
                              {{
                                $vuetify.lang.t(
                                  "$vuetify.renewCertificateInformation"
                                )
                              }}
                            </p>
                            <p class="mb-0">
                              {{
                                $vuetify.lang.t(
                                  "$vuetify.renewCertificateInformation2"
                                )
                              }}
                            </p>
                          </v-tooltip></v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="addTestDuration(result.id, 15)" link>
                        <v-list-item-title
                          >{{ $vuetify.lang.t("$vuetify.addDuration", "15") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="addTestDuration(result.id, 30)" link>
                        <v-list-item-title
                          >{{ $vuetify.lang.t("$vuetify.addDuration", "30") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <modal
      v-model="resetTestDialog"
      :loading="resetTestLoading"
      :handler="resetTest"
      :title="$vuetify.lang.t('$vuetify.modal.resetTest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.resetTest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.resetTest.textButton')"
    />

    <modal
      v-model="forceEndTestDialog"
      :loading="forceEndTestLoading"
      :handler="forceEndTest"
      :title="$vuetify.lang.t('$vuetify.modal.forceEndTest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.forceEndTest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.forceEndTest.textButton')"
      :max-width="'320'"
    />

    <modal
      v-model="forceEndSubtestDialog"
      :loading="forceEndSubtestLoading"
      :handler="forceEndSubtest"
      :title="$vuetify.lang.t('$vuetify.modal.forceEndSubtest.title')"
      :description="
        $vuetify.lang.t('$vuetify.modal.forceEndSubtest.description')
      "
      :text-button="
        $vuetify.lang.t('$vuetify.modal.forceEndSubtest.textButton')
      "
      :max-width="'350'"
    />

    <modal
      v-model="resetSubtestDialog"
      :loading="resetSubtestLoading"
      :handler="resetRunningSubtest"
      :title="$vuetify.lang.t('$vuetify.modal.resetSubtest.title')"
      :description="$vuetify.lang.t('$vuetify.modal.resetSubtest.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.resetSubtest.textButton')"
      :max-width="'350'"
    />
  </v-container>
</template>

<script>
import { differenceInMinutes } from "date-fns";
import { mapActions } from "vuex";
import Modal from "@/components/Modal";
import Test from "@/services/test";
import ResultService from "@/services/result";
import Student from "@/services/student";
import SubtestService from "@/services/subtest";
import TestService from "@/services/test";
import { Chart } from "highcharts-vue";

export default {
  name: "Result.Student.Result",
  data() {
    return {
      results: [],
      loading: true,
      student: {
        user: {
          name: ""
        }
      },
      chartData: [],
      resetTestDialog: false,
      forceEndTestDialog: false,
      forceEndSubtestDialog: false,
      resetSubtestDialog: false,
      resetTestLoading: false,
      resetSubtestLoading: false,
      forceEndTestLoading: false,
      forceEndSubtestLoading: false,
      selectedRunningTestId: "",
      selectedRunningSubtestId: ""
    };
  },
  computed: {
    studentId() {
      return this.$route.params.student_id;
    },
    studentName() {
      return this.student.user.name;
    },
    chartOptions() {
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 1500,
            scrollPositionX: 1
          }
        },
        title: {
          text: `${this.studentName} Test Score`
        },
        xAxis: {
          type: "category"
        },
        yAxis: {
          title: {
            text: "Score"
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: "{point.y}"
            }
          }
        },
        series: [
          {
            name: `${this.studentName} Test Score`,
            colorByPoint: true,
            data: this.chartData
          }
        ]
      };
    }
  },
  components: {
    highcharts: Chart,
    Modal
  },
  created() {
    this.setNavbarTitle("Student Result");
    this.getResults();
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getResults() {
      this.loading = true;
      this.results = [];
      const [results, student] = await Promise.all([
        Test.studentTestResult(this.studentId),
        Student.find(this.studentId)
      ]);
      this.results = results.data.data;
      this.student = student.data.data;
      this.chartData = results.data.data.map(
        ({ test_name: name, test_score: y }) => ({
          name,
          y: y === "-" ? null : parseInt(y)
        })
      );
      this.loading = false;
    },
    parseScoreDisplay({ id, test_end: testEnd, score }, preferGreen = false) {
      if (new Date(testEnd) > new Date()) {
        return {
          color: "warning",
          score: `${differenceInMinutes(new Date(testEnd), new Date())}m`
        };
      }

      if (new Date(testEnd) < new Date() && id !== null && score === "-") {
        return {
          color: "red",
          score: "0m"
        };
      }

      return {
        color: preferGreen ? "green" : "black",
        score
      };
    },
    downloadFile(url) {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.click();
    },
    showResetPrompt(runningTestId) {
      this.selectedRunningTestId = runningTestId;
      this.resetTestDialog = true;
    },
    showForceEndTestPrompt(runningTestId) {
      this.selectedRunningTestId = runningTestId;
      this.forceEndTestDialog = true;
    },
    showForceEndSubtestPrompt(runningSubtestId) {
      this.selectedRunningSubtestId = runningSubtestId;
      this.forceEndSubtestDialog = true;
    },
    showResetSubtestPrompt(runningSubtestId) {
      this.selectedRunningSubtestId = runningSubtestId;
      this.resetSubtestDialog = true;
    },
    async resetTest() {
      try {
        this.resetTestLoading = true;
        await TestService.resetRunningTest(this.selectedRunningTestId);
        this.resetTestDialog = false;
        await this.getResults();
      } finally {
        this.resetTestLoading = false;
      }
    },
    async forceEndTest() {
      try {
        this.forceEndTestLoading = true;
        await TestService.endTest(this.selectedRunningTestId);
        this.forceEndTestDialog = false;
        await this.getResults();
      } finally {
        this.forceEndTestLoading = false;
      }
    },
    async resetRunningSubtest() {
      try {
        this.resetSubtestLoading = true;
        await SubtestService.resetRunningSubest(this.selectedRunningSubtestId);
        this.resetSubtestDialog = false;
        await this.getResults();
      } finally {
        this.resetSubtestLoading = false;
      }
    },
    async startSubtest(running_test_id, subtest_id) {
      await TestService.startSubtest({
        running_test_id,
        subtest_id
      });
      await this.getResults();
    },
    async forceEndSubtest() {
      try {
        this.forceEndSubtestLoading = true;
        await SubtestService.endTest(this.selectedRunningSubtestId);
        this.forceEndSubtestDialog = false;
        await this.getResults();
      } finally {
        this.forceEndSubtestLoading = false;
      }
    },
    async renewCertificate(id) {
      await ResultService.renewCertificate({
        running_test_id: id
      });
      await this.getResults();
    },
    async recalculateSubtestResult(runningSubtestId) {
      await ResultService.recalculateSubtestResult({
        running_subtest_id: runningSubtestId
      });
      await this.getResults();
    },
    async addTestDuration(runningTestId, duration) {
      await TestService.addDuration(runningTestId, { amount: duration });
      await this.getResults();
    },
    async addSubtestDuration(runningSubtestId, duration) {
      await SubtestService.addDuration(runningSubtestId, { amount: duration });
      await this.getResults();
    }
  }
};
</script>

<style scoped>
.link {
  color: black !important;
  text-decoration: underline;
}
</style>
